<template>
  <div>
    <b-row class="match-height">
    <b-col lg="8" md="8" sm="12">
      <div class="daily-review-maintitle">
        <h2>{{ userData.company_name }}</h2>
      </div>
    </b-col>
    <b-col lg="2" md="4" sm="6">
      <b-form-group>
        <v-select
          label="label"
          placeholder="Domicile"
          v-model="selectedDomicile"
          :options="domiciles"
          @input="DomicileSelect()"
        />
      </b-form-group>
    </b-col>
    <b-col lg="2" md="4" sm="6">
      <b-form-group>
        <v-select
          placeholder="Week"
          v-model="selectedWeek"
          :options="option1"
          @input="WeekSelect()"
        />
      </b-form-group>
    </b-col>
  </b-row>
    
    <weekly-dashboard-section2 
    :localObject="localObject"
    :selectedWeek="selectedWeek"
    :selectedDomicile="selectedDomicile"
    />
    <weekly-dashboard-section3 
    :localObject="localObject"
    :selectedWeek="selectedWeek"
    :selectedDomicile="selectedDomicile"
    />

    <weekly-dashboard-section4 
    :localObject="localObject"
    :selectedWeek="selectedWeek"
    :selectedDomicile="selectedDomicile"
    />

    <weekly-dashboard-section5 
    :localObject="localObject"
    :selectedWeek="selectedWeek"
    :selectedDomicile="selectedDomicile"
    />

    <weekly-dashboard-section6 
    :localObject="localObject"
    :selectedWeek="selectedWeek"
    :selectedDomicile="selectedDomicile"
    />



  </div>
</template>

<script>
import WeeklyDashboardSection2 from "./weeklysection2.vue";
import WeeklyDashboardSection3 from "./weeklysection3.vue";
import WeeklyDashboardSection4 from "./weeklysection4.vue";
import WeeklyDashboardSection5 from "./weeklysection5.vue";
import WeeklyDashboardSection6 from "./weeklysection6.vue";

import { BRow, BCol, BFormGroup, BCardText } from "bootstrap-vue";
import moment from "moment";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import axios from "axios";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import Vue from 'vue';

window.EventBus = new Vue();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    WeeklyDashboardSection2,
    WeeklyDashboardSection3,
    WeeklyDashboardSection4,
    WeeklyDashboardSection5,
    WeeklyDashboardSection6,

  },
  props: ['localObject'],
  data() {
    return {
      domiciles: [],
      selectedDomicile: { label: "Domicile", title: "" },
      selectedWeek: { label: "Week", title: "" },
      option1: [],
      userData:getUserData()

    };
  },
  
  created() {
  
    this.getDomicilies();

	const year = moment(new Date().toUTCString()).format('YYYY');

		var curr = new Date(); // get current date

		var curr1 = new Date(); // get current date

		var startDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');

		var endDate = moment(curr1.setDate(curr1.getDate())).format('YYYY-MM-DD');

		var thrWeekDate = moment().subtract(5, 'weeks').endOf('week').format('YYYY-MM-DD');

  },
  beforeCreate() {

    this.option1 = [];

      axios
        .get(`${serverUri}/weekly/get_last_week_num/${getUserData().company_id}`)
        .then((res) => {

          if(res.data.length >0)
          {
           const Response = res.data[0].week_num;

              for(var i=Response; i>=1; i--)
              {
              this.option1.push(
                {
                  title: i,
                  label: i,
                });
              }

          } else {


              for(var i=moment().week(); i>=1; i--)
              {
              this.option1.push(
                {
                  title: i,
                  label: i,
                });
              }
          }

          this.selectedWeek.title = this.option1[0].title;
          this.selectedWeek.label = this.option1[0].label;

          this.WeekSelect();
        });

  },
  methods: {
    filterByWeek(item) {
      console.log(item);
    },

    WeekSelect() 
    {
      if(this.selectedWeek == null)
      {
        this.selectedWeek = { label: "Week", title: "" };
      }

      if(this.selectedDomicile == null)
      {
        this.selectedDomicile = { label: "Domicile", title: "" };
      }

        EventBus.$emit('applyFilter', {week:this.selectedWeek.title,domicile:this.selectedDomicile.title});
    },

    DomicileSelect() 
    {
      if(this.selectedWeek == null)
      {
        this.selectedWeek = { label: "Week", title: "" };
      }

      if(this.selectedDomicile == null)
      {
        this.selectedDomicile = { label: "Domicile", title: "" };
      }

      EventBus.$emit('applyFilter', {week:this.selectedWeek.title,domicile:this.selectedDomicile.title});
    },

    calculatedDayData() 
    {
      this.option1 = [];

      axios
        .get(`${serverUri}/weekly/get_last_week_num/${getUserData().company_id}`)
        .then((res) => {

          if(res.data.length >0)
          {
           const Response = res.data[0].week_num;

              for(var i=Response; i>=1; i--)
              {
              this.option1.push(
                {
                  title: i,
                  label: i,
                });
              }

          } else {


              for(var i=moment().week(); i>=1; i--)
              {
              this.option1.push(
                {
                  title: i,
                  label: i,
                });
              }
          }

        });


    },
    getDomicilies() {
      axios
        .post(`${serverUri}/schedule/getdomicile`, {
          company: getUserData().company_id,
        })
        .then((res) => {
          const Response = res.data.filter((item) => item.domicile?.length > 0);

          Response.map((item) => {
            this.domiciles.push({ title: item.domicile, label: item.domicile });
          });
        });
    },
  },
};
</script>


<style>
.daily-review-maintitle h2 {
  color: rgb(54 32 70);
  font-weight: bold;
  margin: 0px 0px 15px 0px;
}
</style>