<template>
<b-row class="match-height">

<!-- Revenue Graph Starts -->
<b-col md="6">
    <b-card title="Revenue" no-body>
        <b-card-header>
          <b-card-title>Current Revenue</b-card-title>
          <span class="text-end"><feather-icon size="15" icon="AlertCircleIcon" style="width: 15px !important;height: 15px !important;"/></span>
        </b-card-header>
        <hr>
        <b-card-body>
        <span class="total-rev-text">Total Revenue -</span> <span style="font-weight: bold;font-size: 24px;color: #7200F0;">${{ formatPrice(totalRevenueCalculated) }}</span>

            <vue-apex-charts type="bar" height="300" :options="chartOptions" :series="series"></vue-apex-charts>
            
        </b-card-body>
    </b-card>
</b-col>
<!-- Revenue Graph Ends -->

<!-- Revenue Graph 12 week Starts -->
<b-col md="6">
    <b-card title="Revenue" no-body>
        <b-card-header>
          <b-card-title>Revenue Trend</b-card-title>
          <span class="text-end"><feather-icon size="15" icon="AlertCircleIcon" style="width: 15px !important;height: 15px !important;"/></span>
        </b-card-header>
        <hr>
        <b-card-body>
        <!-- <span class="total-rev-text">Total Revenue Trend -</span> <span style="font-weight: bold;font-size: 24px;color: #7200F0;">${{ formatPrice(totalRevenueTwelveWeekCalculated) }}</span> -->

            <vue-apex-charts type="bar" height="300" :options="newChartOptions" :series="newSeries"></vue-apex-charts>
            
        </b-card-body>
    </b-card>
</b-col>
<!-- Revenue Graph 12 week Ends -->

</b-row>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import VueApexCharts from 'vue-apexcharts'
import ApexCharts from 'apexcharts'
import vSelect from 'vue-select'
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import Vue from 'vue';

window.eventBus = new Vue();

import {
  BTable, BFormCheckbox, BAvatar, BBadge, BCardBody,BRow,BCol,BCard, BCardHeader, BCardTitle, BCardText,BFormGroup,BMedia,
   BMediaAside, BMediaBody, 
} from 'bootstrap-vue'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#7367f0'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    ApexCharts,
  },
  props: ['localObject', 'selectedWeek','selectedDomicile'],

  data() {
    return {
      totalRevenueCalculated : 0,
      totalRevenueTwelveWeekCalculated : 0,

      series: [
        {
          name: "Current Week",
          data: [],
        },
        {
          name: "Prior 3 Wk Avg",
          data: [],
        },
      ],

      newSeries: [
        {
          name: "12 Weeks",
          data: [],
        },
      ],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 300,
              toolbar: {
                show: false
                },
            },
            legend: {
              show: true
            },
            plotOptions: {
              bar: {
                columnWidth: '25px', 
                horizontal: false,
                dataLabels: {
                  position: 'top',
                },
                // distributed: true,
                // colors: {
                //   backgroundBarColors: ['#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe']
                // },
              }
            },
            
            dataLabels: {
            
              enabled: false,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#f8f2fe']
              }
            },
            stroke: {
            
              show: true,
              width: 1,
              colors: ['#f8f2fe']
            },
            tooltip: {
              shared: true,
              intersect: false,
              followCursor: true,
              y: {
                formatter: (y) => {
                  return '$' + y.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              },
            },
          },
            yaxis: {
              categories: ["ram", "sham"],
              "labels": {
                formatter: function (val) {
                    return '$' + val.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                }
              },
            },
        xaxis: {
          categories: ["Sat", "Sun", "Mon", "Tues", "Wed", "Thur", "Fri"],
        },
        colors: ["#7200F0", "#c9c4ff"],
          },

          newChartOptions: {
            chart: {
              type: 'bar',
              height: 300,
              toolbar: {
                show: false
                },
            },
            legend: {
              show: true
            },
            plotOptions: {
              bar: {
                columnWidth: '25px', 
                horizontal: false,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            
            dataLabels: {
            
              enabled: false,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#f8f2fe']
              }
            },
            stroke: {
            
              show: true,
              width: 1,
              colors: ['#f8f2fe']
            },
            tooltip: {
              shared: true,
              intersect: false,
              followCursor: true,
              y: {
                formatter: (y) => {
                  return '$' + y.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              },
            },
          },
            yaxis: {
              categories: ["ram", "sham"],
              "labels": {
            formatter: function (val) {
                return '$' + val.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            }
        },
            },
        xaxis: {
          categories: [],
        },
        colors: ["#7200F0"],
          },
    }
  },
  beforeDestroy() {
    EventBus.$off("applyFilter");
  },
  created() {
    this.getRevenueData(this.selectedWeek.title,this.selectedDomicile.title);
    this.getRevenueTwelveWeekData(this.selectedWeek.title,this.selectedDomicile.title);

    EventBus.$on('applyFilter', (item) => {
      this.getRevenueData(item.week,item.domicile);
      this.getRevenueTwelveWeekData(item.week,item.domicile);

    });
  },
  methods: {

    getWeekLabels(currentWeek,type)
    {
      var WeekEnd;
      var WeekStart;

      if(type == 'default')
      {
         WeekEnd = parseInt(currentWeek) -1;
         WeekStart   = parseInt(currentWeek) - 12;

      } else {

         WeekEnd = parseInt(currentWeek);
         WeekStart   = parseInt(currentWeek) - 11;
      }

      const weekLabelsData = [];

      for(var i=WeekStart; i<WeekEnd; i++)
      {
        var WeekNumber = i + 1;
        weekLabelsData.push('Week '+WeekNumber);
      
      }

      this.newChartOptions = {...this.newChartOptions, ...{
          xaxis: {
            categories: weekLabelsData
          }
      }}

    },

    formatPrice(value) {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
    },

    async getRevenueData(week,domicile)
    {
   
      try {

        const response = await this.$http.post(
          `${serverUri}/weekly/revenue_data/${getUserData().company_id}`,
          {
            week:week,
            domicile:domicile
          }
        );

        let Array = [];
        response.data.weekly_data.map((item) => {

          if(item.totalSpotRevenue == null)
          {
            Array[item.Day] = item.totalRevenue

          } else {

            Array[item.Day] = item.totalRevenue + item.totalSpotRevenue

          }
          });

          let ThreeWeekArray = [];
          response.data.three_week_data.map((item) => {

            if(item.totalSpotRevenue == null)
            {
                const AvgRevenue = parseInt(item.totalRevenue / 3);
                ThreeWeekArray[item.Day] = AvgRevenue;

            } else {

              const TotalRev = parseInt(item.totalRevenue) + parseInt(item.totalSpotRevenue);
              const AvgRevenue = parseInt(TotalRev / 3);
                ThreeWeekArray[item.Day] = AvgRevenue;

            }
          });

          var Days = [
            "Saturday",
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
          ];

          var RevenueOneArray = [];
          var RevenueTwoArray = [];

          for (let i = 0; i < Days.length; i++) {
            var Data = Array[Days[i]] == undefined ? 0 : Array[Days[i]];
            var Data1 =
              Array[Days[i]] == undefined ? 0 : ThreeWeekArray[Days[i]];

              RevenueOneArray.push(Data);
              RevenueTwoArray.push(Data1);
          }

          this.series = [];
          this.series.push({ name: "Current Week", data: RevenueOneArray });
          this.series.push({
            name: "Prior 3 Wk Avg",
            data: RevenueTwoArray,
          });


          this.totalRevenueCalculated = RevenueOneArray.reduce(
            (total, num) => total + num,
            0
          );

          this.totalRevenueCalculated = parseFloat(this.totalRevenueCalculated);

      } catch (error) {
        console.log(error);
      }
    },

    async getRevenueTwelveWeekData(week,domicile)
    {
   
      try {

        const response = await this.$http.post(
          `${serverUri}/weekly/twelve_week_revenue_data/${getUserData().company_id}`,
          {
            week:week,
            domicile:domicile
          }
        );

        var RevenueOneArray = [];
        var WeeksArray = [];

        response.data.data.map((item) => {

          if(item.total_spot_revenue ==null)
          {
            var TotalRevenueAmount = parseInt(item.total_revenue);

          } else {
            var TotalRevenueAmount = parseInt(item.total_revenue) + parseInt(item.total_spot_revenue);

          }
            RevenueOneArray.push(TotalRevenueAmount);
            WeeksArray.push(item.week_num);
          });    
          
          


          this.newSeries = [];
          this.newSeries.push({ name: "12 Weeks", data: RevenueOneArray });
        

          this.totalRevenueTwelveWeekCalculated = RevenueOneArray.reduce(
            (total, num) => total + num,
            0
          );

          console.log(this.totalRevenueTwelveWeekCalculated);


          this.newChartOptions = {...this.newChartOptions, ...{
          xaxis: {
            categories: WeeksArray
          }
        }}


          this.totalRevenueTwelveWeekCalculated = parseFloat(this.totalRevenueTwelveWeekCalculated);

      } catch (error) {
        console.log(error);
      }
    }
  },

}
</script>
<style>
.total-rev-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #2a0f4c85;
}
</style>