<template>
  <b-row class="match-height">
    <!-- Revenue Graph Starts -->
    <b-col md="6">
      <b-card title="Revenue" no-body>
        <b-card-header>
          <b-card-title>Fuel</b-card-title>
        </b-card-header>
        <hr />
        <b-card-body>
          <span class="total-rev-text">Fuel Purchased - </span
          ><span style="font-weight: bold; font-size: 24px; color: #7200f0"
            >${{ formatPrice(totalFuelCalculated) }}</span
          >

          <vue-apex-charts
            type="bar"
            height="300"
            :options="chartOptions"
            :series="series"
          ></vue-apex-charts>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card title="Revenue" no-body>
        <b-card-header>
          <b-card-title>Miles & MPG</b-card-title>
        </b-card-header>
        <hr />
        <b-card-body>
          <span class="total-rev-text">Miles Travelled - </span
          ><span
            style="font-weight: bold; font-size: 24px; color: #7200f0"
            class="mr-4"
            >{{formatPrice(totalMilesCalculated)}}</span
          >
          <span class="total-rev-text">MPG - </span
          ><span
            style="font-weight: bold; font-size: 24px; color: #7200f0"
            class="mr-4"
            >{{ (this.totalMilesCalculated/this.totalFuelQtyCalculated).toFixed(2) }}</span
          >

          <vue-apex-charts
            type="bar"
            height="300"
            :options="milesChartOptions"
            :series="milesSeries"
          ></vue-apex-charts>

        </b-card-body>
      </b-card>
    </b-col>
    <!-- Miles Graph Ends -->
  </b-row>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import VueApexCharts from "vue-apexcharts";
import ApexCharts from "apexcharts";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import Vue from 'vue';

window.eventBus = new Vue();

import {
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BCardBody,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#7367f0";

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    ApexCharts,
  },
  props: ['localObject', 'selectedWeek','selectedDomicile'],

  data() {
    return {
      totalFuelCalculated: 0,
      totalFuelQtyCalculated: 0,
      totalMilesCalculated: 0,
      series: [
        {
          name: "Current Week",
          data: [],
        },
        {
          name: "Prior 3 Wk Avg",
          data: [],
        },
      ],
      milesSeries: [
        {
          name: "Miles",
          data: [],
        },
        {
          name: "Miles2",
          data: [],
        },
      ],

      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        plotOptions: {
          bar: {
            columnWidth: "25px",
            horizontal: false,
            dataLabels: {
              position: "top",
            },
            // colors: {
            //   backgroundBarColors: ['#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe']
            // }
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
                formatter: (y) => {
                  return '$' + y.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              },
        },
      },
        yaxis: {
          categories: ["ram", "sham"],
          "labels": {
            formatter: function (val) {
                return '$' + val.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            }
        },
      },
        xaxis: {
          categories: ["Sat", "Sun", "Mon", "Tues", "Wed", "Thur", "Fri"],
        },
        colors: ["#7200F0", "#c9c4ff"],
      },

      milesChartOptions: {
        chart: {
          type: "bar",
          height: 300,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        plotOptions: {
          bar: {
            columnWidth: "25px",
            horizontal: false,
            dataLabels: {
              position: "top",
            },
            // colors: {
            //   backgroundBarColors: ['#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe','#f8f2fe']
            // }
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
                formatter: (y) => {
                  return y.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              },
        },
      },
        yaxis: {
          categories: ["ram", "sham"],
          "labels": {
            formatter: function (val) {
                return val.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            }
        },
                },
        xaxis: {
          categories: ["Sat", "Sun", "Mon", "Tues", "Wed", "Thur", "Fri"],
        },
        colors: ["#7200F0", "#c9c4ff"],
      },
    
          
      Days : [
          "Saturday",
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
        ],
    };
  },
  beforeDestroy() {
    EventBus.$off("applyFilter");
  },
  created() {
    this.getFuelData(this.selectedWeek.title,this.selectedDomicile.title);
    this.getMilesData(this.selectedWeek.title,this.selectedDomicile.title);

    EventBus.$on('applyFilter', (item) => {
      this.getFuelData(item.week,item.domicile);
      this.getMilesData(item.week,item.domicile);
    });
  },
  methods: {
    formatPrice(value) {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
    },
    async getFuelData(week,domicile) 
    {
      try {
        const response = await this.$http.post(
          `${serverUri}/weekly/fuel_data/${getUserData().company_id}`, { week:week,domicile:domicile}
        );

        let Array = [];
        let Array1 = [];

        if(response.data.weekly_data != undefined)
        {
          response.data.weekly_data.map((item) => {
            Array[item.Day] = item.totalFuel;
            Array1[item.Day] = item.totalFuelQty;
          });
        }

        let ThreeWeekArray = [];
        if(response.data.three_week_data.length >0)
        {
          response.data.three_week_data.map((item) => {
            const AvgFuels = parseInt(item.totalFuel / 3);
            ThreeWeekArray[item.Day] = AvgFuels;
          });
        }

        var FuelOneArray = [];
        var FuelTwoArray = [];
        var FuelThreeArray = [];

        for (let i = 0; i < this.Days.length; i++) 
        {
          var Data = Array[this.Days[i]] == undefined ? 0 : Array[this.Days[i]];
          var Data1 = Array[this.Days[i]] == undefined ? 0 : ThreeWeekArray[this.Days[i]];
          var Data2 = Array1[this.Days[i]] == undefined ? 0 : Array1[this.Days[i]];

          FuelOneArray.push(Data);
          FuelTwoArray.push(Data1);
          FuelThreeArray.push(Data2);

        }

        this.series = [];
        this.series.push({ name: "Current Week", data: FuelOneArray });
        this.series.push({
          name: "Prior 3 Wk Avg",
          data: FuelTwoArray,
        });

        this.totalFuelCalculated = FuelOneArray.reduce(
          (total, num) => total + num,
          0
        );

        this.totalFuelQtyCalculated = FuelThreeArray.reduce(
          (total, num) => total + num,
          0
        );

        this.totalFuelCalculated = parseFloat(this.totalFuelCalculated);
        this.totalFuelQtyCalculated = parseInt(this.totalFuelQtyCalculated);

      } catch (error) {
        console.log(error);
      }
    },
    async getMilesData(week,domicile) {
      try {
        const res = await this.$http.post(
          `${serverUri}/weekly/miles_data/${getUserData().company_id}`,{ week:week,domicile:domicile}
        );
        
        let Array = [];
          res.data.weekly_data.map((item) => {

              var spotCurrentWeekMiles = item.total_spot_miles != null ? item.total_spot_miles : 0;

              var TotalCurrentWeekMiles = parseInt(spotCurrentWeekMiles) + parseInt(item.totalMiles);

              Array[item.Day] = TotalCurrentWeekMiles;
          });

          let ThreeWeekArray = [];
          res.data.three_week_data.map((item) => {

            var spotThreeWeelMiles = item.total_spot_miles != null ? item.total_spot_miles : 0;
            var TotalThreeWeekMiles = parseInt(spotThreeWeelMiles) + parseInt(item.totalMiles);
            const AvgMiles = parseInt(TotalThreeWeekMiles / 3);
            ThreeWeekArray[item.Day] = AvgMiles;

            // ThreeWeekArray.push({day:item.day,totalMiles:AvgMiles});
          });


          var MilesOneArray = [];
          var MilesTwoArray = [];

          for (let i = 0; i < this.Days.length; i++) {
            var Data = Array[this.Days[i]] == undefined ? 0 : Array[this.Days[i]];
            var Data1 =
              Array[this.Days[i]] == undefined ? 0 : ThreeWeekArray[this.Days[i]];

            MilesOneArray.push(Data);
            MilesTwoArray.push(Data1);
          }

          this.milesSeries = [];
          this.milesSeries.push({ name: "Current Week", data: MilesOneArray });
          this.milesSeries.push({
            name: "Prior 3 Wk Avg",
            data: MilesTwoArray,
          });

          this.totalMilesCalculated = MilesOneArray.reduce(
            (total, num) => total + num,
            0
          );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.total-rev-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #2a0f4c85;
}
</style>