<template>
  <b-row class="match-height">
    <!-- Miles Graph Starts -->
    <b-col md="12">
      <b-card-code title="Tractor Details" no-body>
        <b-table
          :sticky-header="stickyHeader"
          :no-border-collapse="noCollapse"
          class="revmilegraph"
          responsive
          :items="items"
          :fields="fields"
          paginated="false"
        >
          <template #head(id)>
            <div class="text-nowrap">Tractor</div>
          </template>

          <template #head()="scope">
            <div class="text-nowrap">
              {{ scope.label }}
            </div>
          </template>

          <template #cell(total_revenue)="data">
            {{
              data.item.total_revenue_new == 0
                ? "$" + data.item.total_revenue_new
                : "$" + data.item.total_revenue_new
            }}

            <span :class="data.item.week_revenue <0 ? 'badge ml-1 badge-light-danger' : 'badge ml-1 badge-light-success'" v-if="data.item.week_revenue">
              <span :class="data.item.week_revenue <0 ? 'text-danger mr-50' : 'text-success mr-50'">
                <feather-icon
                  size="28"
                  :icon="data.item.week_revenue <0 ? 'ArrowDownCircleIcon' : 'ArrowUpCircleIcon'"
                  style="width: 15px !important; height: 15px !important"
                /> 
                {{ removeMinus(data.item.week_revenue) }}%</span
              >
            </span>
          </template>

          <template #cell(total_fuels)="data">
            {{
              data.item.total_fuels_new == 0
                ? "$" + data.item.total_fuels_new
                : "$" + data.item.total_fuels_new
            }}
          </template>

          <template #cell(total_miles)="data">
            {{ data.value }}
            <span :class="data.item.week_miles < 0 ? 'badge ml-1 badge-light-danger' : 'badge ml-1 badge-light-success'" v-if="data.item.week_miles">
              <span :class="data.item.week_miles < 0 ? 'text-danger mr-50' : 'text-success mr-50'">
                <feather-icon
                  size="28"
                  :icon="data.item.week_miles < 0 ? 'ArrowDownCircleIcon' : 'ArrowUpCircleIcon'"
                  style="width: 15px !important; height: 15px !important"
                />
                {{ removeMinus(data.item.week_miles) }}%</span>
            </span>
          </template>

          <template #cell(drivers)="data">
            <b-avatar-group size="32px" v-if="data.item.drivers.length > 0">
              <div
                v-for="(single_driver, index) in data.item.drivers"
                :key="index"
              >
                <b-avatar
                  v-b-tooltip.hover
                  class="pull-up avatar_b"
                  :title="single_driver.driver_name"
                  :src="
                    single_driver.photo == null
                      ? require('@/assets/images/portrait/small/avatar-s-5.jpg')
                      : single_driver.photo
                  "
                />
              </div>
            </b-avatar-group>
          </template>

          <!-- <template #cell(total_revenue)="data">
        {{ data.value }}
        <span class="badge ml-1 badge-light-success">
            <span class="text-success mr-50"> <feather-icon size="28" icon="ArrowUpCircleIcon" style="width: 15px !important;height: 15px !important;"/> 5.2%</span> 
        </span>
      </template>

      <template #cell(total_fuels)="data">
        {{ data.value }}
        <span class="badge ml-1 badge-light-success">
            <span class="text-success mr-50"> <feather-icon size="28" icon="ArrowUpCircleIcon" style="width: 15px !important;height: 15px !important;"/> 5.2%</span> 
        </span>
      </template> -->

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>

          <template #cell()="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-card-code>
    </b-col>
    <!-- Miles Graph Ends -->
  </b-row>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import VueApexCharts from "vue-apexcharts";
import ApexCharts from "apexcharts";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import axios from "axios";

import Vue from "vue";

window.eventBus = new Vue();

import {
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BCardBody,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatarGroup,
  VBTooltip,
} from "bootstrap-vue";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#7367f0";

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    ApexCharts,
    BAvatarGroup,
    VBTooltip,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
  props: ['localObject', 'selectedWeek','selectedDomicile'],

  data() {
    return {
      selected: { title: "Active" },
      option: [{ title: "Active" }, { title: "Inactive" }],
      stickyHeader: true,
      noCollapse: true,
      fixed: true,

      fields: [
        { key: "vehicle", label: "Tractor",sortable: true },
        { key: "total_revenue", label: "Revenue",sortable: true },
        { key: "total_miles", label: "Miles",sortable: true },
        { key: "total_fuels", label: "Fuel Purchases",sortable: true },
        { key: "drivers", label: "Drivers" },
      ],
      items: [
        // {
        //   id: 13545,
        //   revenue: '$32,232',
        //   miles: '120',
        //   fuel:'$2,234',
        //   // eslint-disable-next-line global-require
        //   avatar: require('@/assets/images/avatars/10-small.png'),
        //   full_name: "Korrie O'Crevy",
        //   age: '61',
        //   experience: '1 Year',
        //   status: 2,
        // },
      ],
      lastWeekItems:[],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  beforeDestroy() {
    EventBus.$off("applyFilter");
  },

  created() {
    this.getVehicleData(this.selectedWeek.title,this.selectedDomicile.title);

    EventBus.$on("applyFilter", (item) => {
      this.getVehicleData(item.week,item.domicile);
    });
  },
  methods: {

    removeMinus(value) {
     return Math.abs(value);

    },
    formatPrice(value) {
      if(value == null)
      {
        return 0;
      } else {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");

      }
    },

    async getVehicleData(week,domicile) {
      try {

        axios
        .post(
          `${serverUri}/weekly/drivers_last_week_data/${
            getUserData().company_id
          }`,
          {
            week: week,
            domicile:domicile
          }
        )
        .then((response) => {
          this.lastWeekItems = [];
          this.lastWeekItems = response.data.data;
         
        });

        const response = await this.$http.post(
          `${serverUri}/weekly/get_vehicles_data/${getUserData().company_id}`,
          {
            week: week,
            domicile: domicile
          }
        );

        if (response.data.status == false) {
          this.items = [];
        } else {
          this.items = [];

         var AllVehicles = response.data.data;
          
         AllVehicles.map((item, index) => {

            // if(item.total_miles == null)
            // {
            //   var totalMiles = parseInt(item.total_miles)

            // } else {

            //   var totalMiles = parseInt(item.total_miles) + parseInt(item.total_spot_miles)
            // }

            if(item.total_spot_miles == null)
            {
              var totalMiles = parseInt(item.total_miles)

            } else {

              var totalMiles = item.total_spot_miles == null  ? parseInt(item.total_miles) :parseInt(item.total_miles) + parseInt(item.total_spot_miles)
            }

            if(item.total_spot_revenue == null)
            {
              var totalRevenue = parseInt(item.total_revenue)

            } else {

              var totalRevenue = parseInt(item.total_revenue) + parseInt(item.total_spot_revenue)
            }

            this.items.push({
              vehicle: item.vehicle,
              total_revenue_new: this.formatPrice(totalRevenue),
              total_revenue: totalRevenue,
              total_fuels_new: this.formatPrice(item.total_fuels),
              total_fuels: item.total_fuels,
              total_miles: totalMiles,
              drivers: JSON.parse(item.drivers),
            });

            this.lastWeekItems.map((last_week_items,index)=> {

              this.items.map((items,index1)=> {
                if(last_week_items.vehicle == items.vehicle) // 
                {

                  if(last_week_items.total_spot_revenue == null)
                  {
                      var lastWeekRevenue = last_week_items.total_revenue;

                  } else {

                     var lastWeekRevenue = parseInt(last_week_items.total_revenue) +  parseInt(last_week_items.total_spot_revenue);

                  }

                  if(last_week_items.total_spot_miles == null)
                  {
                      var lastWeekMiles = last_week_items.total_miles;

                  } else {

                     var lastWeekMiles = parseInt(last_week_items.total_miles) +  parseInt(last_week_items.total_spot_miles);

                  }

                    var RevenueCal = items.total_revenue - lastWeekRevenue

                    var TotalRevenue = parseFloat(items.total_revenue_new) + parseFloat(lastWeekRevenue);

                    const calculatedRevenue = (RevenueCal / TotalRevenue) * 100;

                    var MilesCal = items.total_miles - lastWeekMiles

                    var TotalMiles = parseFloat(items.total_miles) + parseFloat(lastWeekMiles);

                    const calculatedMiles = (MilesCal / TotalMiles) * 100;

                    this.items[index1].last_week_revenue    = lastWeekRevenue
                    this.items[index1].week_revenue         = calculatedRevenue.toFixed(2)
                    this.items[index1].total_revenue        =  items.total_revenue
                    this.items[index1].total_fuels          =  items.total_fuels
                    this.items[index1].week_miles           = calculatedMiles.toFixed(2)
                    this.items[index1].last_week_miles      = lastWeekMiles
                }
              });

            });
          });


        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.code-toggler {
  display: none;
}
.todo-select {
  width: 120px;
}
.todo-body .card-header {
  padding: 1.1rem 1.5rem;
}
.todo-body .card-body {
  padding: 0px;
}
.todo-body hr {
  margin: 0px;
}
.todo-body .form-group {
  margin-bottom: 0px;
}
.to-do-tick {
  color: #7367f0;
}
.revmilegraph th .text-nowrap {
  color: #2a0f4c8f;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
h4 {
  //font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2a0f4c;
}
.avatar_b {
  max-width: none !important;
}
</style>