<template>
    <b-row class="match-height">
        <b-col md="12">
            <b-card title="Upcoming PM" no-body>
                <b-card-header>
                    <b-card-title>Notes</b-card-title>
                </b-card-header>
                <hr>
                <b-card-body>
                    <b-row>
                        <b-col md="8">
                            <div>
                                <span class="section7-title">Add Notes</span>
                                <b-form-input
                                placeholder="Write Note Title..."
                                v-model="note_title"
                                />
                                <br>
                                <b-form-textarea
                                id="textarea-default"
                                placeholder="Write Note..."
                                v-model="note_details"
                                rows="6"
                                />
                                <br>
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="SaveNotes">Save</b-button>
                            </div>
                        </b-col>
                        <b-col md="4" class="note-alert">
                            <span class="section7-title">Available Notes ({{ this.allnotes.length }}) <span @click=ViewAllNotes() v-if="this.allnotes.length>1" style="float: right;color: #7367f0;cursor:pointer">View All</span> </span>
                                <b-alert variant="dark" show>
                                <div class="alert-body" v-if="this.allnotes.length>0">
                                    <span><strong>{{ this.allnotes[0].title }}</strong> 
                                    <br> <br>
                                    {{ this.allnotes[0].notes }}
                                    <br><br>
                                    <b-avatar class="mr-1" :src="userData.avatar" />
                                    {{userData.full_name }} <span class="ml-4">Posted on: {{ dateFormat(this.allnotes[0].date) }}</span>
                                    </span>
                                </div>

                                <div class="alert-body" v-if="this.allnotes.length==0">
                                   No Notes Found
                                </div>
                                </b-alert>
                        </b-col>
                    </b-row>
                </b-card-body>
              </b-card>
          </b-col>

        <b-modal
      size="lg"
      id="modal-center"
      centered
      title="All Notes"
      ok-only
      ok-title="Cancel"
      v-model="showModal"
    >
      <b-row style="height: 500px;overflow: auto;">
        <b-col cols="11" v-if="this.allnotes.length>1">
          <div class="alert-body p-2 m-2 notes_list" v-for="(single_note,index) in this.allnotes"  :key="index">
              <span><strong>{{ single_note.title }}</strong> 
                <br> <br>
                {{ single_note.notes }}
                <br><br>
                <b-avatar class="mr-1" :src="userData.avatar" />
                  {{userData.full_name }} <span class="ml-4">Posted on: {{ dateFormat(single_note.date) }}</span>
              </span>
          </div>
         
        </b-col>
      </b-row>
    </b-modal>


    </b-row>
    
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import VueApexCharts from 'vue-apexcharts'
import ApexCharts from 'apexcharts'
import vSelect from 'vue-select'
import axios from "axios";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import moment from "moment";
import Store from "@/store";

import {
  BTable, BFormCheckbox, BAvatar, BBadge, BCardBody,BRow,BCol,BCard, BCardHeader, BCardTitle, BCardText,BFormGroup,BMedia,
   BMediaAside, BMediaBody, BFormTextarea,BButton,BAlert,  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
  BButton,
  BAlert,
  BFormTextarea,
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    ApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allnotes : [],
      note_title:'',
      note_details:'',
      userData: getUserData(),
      showModal:false
    }
  },
  created() {
    this.AllNotes()
    
  },
  methods:  {
    dateFormat(value)
    {
      return moment(value).format('MM/DD/YYYY')
    },
    SaveNotes()
    {
      if(this.note_title == '')
      {
        this.showToast('danger', 'Warning', 'Please fill note title');

      } else if(this.note_details=='') {

        this.showToast('danger', 'Warning', 'Please fill note details');

      } else {

        axios
          .post(`${serverUri}/add_notes`, {
            user_id:this.userData.id,
            company_id:this.userData.company_id,
            title:this.note_title,
            notes:this.note_details,
          })
          .then((response) => {

            this.showToast('success', 'Success', 'Note details has been saved!');
            this.note_title = '';
            this.note_details = '';

              this.AllNotes();
              
          });
        }
    },
    showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },

        ViewAllNotes()
        {
          this.showModal = true;
        },

    AllNotes()
    {
      axios
        .post(`${serverUri}/all_notes`, {
          company_id:this.userData.company_id,
          user_id:this.userData.id,
        })
        .then((response) => {

          if(response.data.data)
          {
            this.allnotes = response.data.data;

          } else {
            this.allnotes = [];
          }
            
        });
    }
  }
}
</script>
<style>
hr {
    margin:0 !important;
}
.section7-title {
    /* font-family: Open Sans; */
    font-size: 16px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #2A0F4C;
}
.note-alert .alert-dark {
    background: #2A0F4C;
}

.notes_list {
  background: #ccc;
    border-radius: 5px;
    color: #4b4b4b !important;
}

</style>