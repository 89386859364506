<template>
<b-row class="match-height">
<!-- Compliance Starts -->
<b-col md="6">
    <b-row class="match-height">
        <b-col md="12">
            <b-card title="Upcoming PM" no-body>
                <b-card-header>
                <b-card-title>Fuel Reimbursement   </b-card-title>
                </b-card-header>
                <hr>
                <b-card-body>
                <b-row>
                    <b-col md="6">
                        <b-col
                            v-for="item in statisticsItems"
                            :key="item.icon"
                            md="10"
                            sm="12"
                            class="custom-badge-card"
                            >
                        <b-card no-body class="card-statistics mt-4 p-0" >
                            <b-card-body class="statistics-body p-0 badge badge-light-secondary">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                <b-avatar size="48" :variant="item.color">
                                    <img :src="item.mySVG" />
                                </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                <b-card-text class="font-small-3 mb-0 top-small-heading">
                                    {{ item.subtitle }}
                                </b-card-text>
                                <h4 class="font-weight-bolder mb-0 numbering-result">
                                    {{ item.title }}
                                </h4>
                                </b-media-body>
                            </b-media>
                            </b-card-body>
                        </b-card>
                        </b-col>
                    </b-col>
                    <b-col md="6" style="text-align:right">
                        <vue-apex-charts
                            type="radialBar"
                            height="400"
                            class="apexchartforqvistatus"
                            :options="trainingRadialBar"
                            :series="goalOverview.trainingseries"
                            />
                        <br>
                        </div>
                    </div>
                    </b-col>
                </b-row>
                    
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</b-col>

    <!-- Compliance End -->
    <!-- Payroll Starts -->

   <b-col md="6">
    <b-card title="VEDR" no-body>
        <b-card-header>
        <b-card-title>Payroll  <b-spinner variant="primary" label="Data Sync of current week" v-if="dataLoad"/></b-card-title>
        </b-card-header>
        <hr>
        <b-card-body>
            <b-row>
                <b-col md="12">
                <span class="total-rev-text">Total Payroll -</span> <span style="font-weight: bold;font-size: 24px;color: #7200F0;">${{ formatPrice(totalPayroll) }}</span><br><br>
                    <!-- <chartjs-component-line-chart
                        :height="300"
                        :data="lineChart.data"
                        :options="lineChart.options"
                        ref='chart'
                    /> -->

                    <apexchart type="line" height="350" :options="lineChartOptions" :series="lineChartSeries"  ref='chart'></apexchart>

                    <!-- :plugins="plugins" -->

                </b-col>
            </b-row>
        </b-card-body>
        
    </b-card>
    </b-col>

    <!-- VEDR Code Ends -->


  </b-row>
</template>

<script>
// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};
import { $themeColors } from "@themeConfig";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import VueApexCharts from "vue-apexcharts";
import ApexCharts from "apexcharts";
import moment from "moment";
import vSelect from "vue-select";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue";
import axios from "axios";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import Vue from "vue";

window.eventBus = new Vue();

import {
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BCardBody,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BSpinner
} from "bootstrap-vue";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#7200F0";
const $goalStrokeColor2 = "#1ECF72";

export default {
  components: {
    ChartjsComponentLineChart,
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    apexchart: VueApexCharts,
    StatisticCardVertical,
    BSpinner
  },
  props: ["localObject", "selectedWeek", "selectedDomicile"],

  data() {
    return {
      totalPayroll: 0,
      dataLoad:true,
      PayrollData: [],
      payroll_items : [],
      weekLabels:[],
      lineChartSeries: [
        {
          name: "Payroll Amount",
          data: [],
        },
      ],
      lineChartOptions: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false,
          },
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
                formatter: (y) => {
                  return '$' + y.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              },
        },
      },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        //#2dc76e
        xaxis: {
          categories: [],
        },
        yaxis: {
          "labels": {
            formatter: function (val) {
                return '$' + val.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            }
        },
      },
        colors: ["#2dc76e", "#2dc76e"],
      },
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: true,
          backgroundColor: false,
          height: 300,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.white,
            titleFontColor: $themeColors.black,
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,

                  zeroLineColor: "#fff",
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: this.totalPayroll,
                },
                fontColor: chartColors.labelColor,
                gridLines: {
                  display: true,

                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            display: false,
          },
        },
        data: {
          labels: [
            "Week 1",
            "Week 2",
            "Week 3",
            "Week 4",
            "Week 5",
            "Week 6",
            "Week 7",
          ],
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0],
              label: "Week",
              borderColor: "#1ECF72",
              fill: false,
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "#ccc",
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: "#fff",
            },
          ],
        },
      },
      statisticsItems: [
        {
          icon: "DollarSignIcon",
          color: "light-primary",
          title: "$0",
          subtitle: "Missed fuel savings",
          customClass: "mb-2 mb-xl-0",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "5.2% vs last week",
          mySVG: require("@/assets/images/svg/mdi_fossil-fuel-outline-below.svg"),
        },
      ],
     
      
      goalOverview: {
        completed: "786,617",
        inProgress: "13,561",
        series: [90],
        trainingseries: [0],
      },
      trainingRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "70%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "70%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      goalOverviewRadialBar: {
        chart: {
          height: 350,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 0,
            top: 0,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            startAngle: -100,
            endAngle: 100,
            hollow: {
              size: "60%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "90%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "1.8rem",
                fontWeight: "bold",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#1ECF72"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {},
        grid: {
          padding: {
            bottom: 0,
          },
        },
      },
      selected: { title: "Active" },
      option: [{ title: "Active" }, { title: "Inactive" }],
      stickyHeader: true,
      noCollapse: true,
      fixed: true,

    };
  },
  watch: {
    savings: {
      deep: true,
      handler() {
        console.clear();

        this.$refs["chart"].destroy();
      },
    },
  },
  beforeDestroy() {
    EventBus.$off("applyFilter");
  },
  created() {
    this.getWeekLabels(moment().week(),'default');
    this.getDriverPayrollData(
      this.selectedWeek.title,
      this.selectedDomicile.title
    );
    this.getFuelData(this.selectedWeek.title, this.selectedDomicile.title);

    EventBus.$on("applyFilter", (item) => {
      this.getWeekLabels(item.week,'current');
      this.getDriverPayrollData(item.week, item.domicile);
      this.getFuelData(item.week, item.domicile);
    });
  },
  methods: {

    getWeekLabels(currentWeek,type)
    {
      var WeekEnd;
      var WeekStart;

      if(type == 'default')
      {
         WeekEnd = parseInt(currentWeek) -1;
         WeekStart   = parseInt(currentWeek) - 8;

      } else {

         WeekEnd = parseInt(currentWeek);
         WeekStart   = parseInt(currentWeek) - 7;
      }

      const weekLabelsData = [];

      for(var i=WeekStart; i<WeekEnd; i++)
      {
        var WeekNumber = i + 1;
        weekLabelsData.push('Week '+WeekNumber);
      
      }

      this.lineChartOptions = {...this.lineChartOptions, ...{
          xaxis: {
            categories: weekLabelsData
          }
      }}

    },

    formatPrice(value) {
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },

    getFuelData(week, domicile) {
      axios
        .post(
          `${serverUri}/weekly/fuel_reimburshment_data/${
            getUserData().company_id
          }`,
          {
            week: week,
            domicile: domicile,
          }
        )
        .then((response) => {
          const Response = response.data.data;

          if (Response[0] != undefined) {
            const MissedFuels =
              parseFloat(Response[0].total_fuel_purchased) -
              parseFloat(Response[0].total_fuel_supp_amt);

            this.statisticsItems[0].title = "$" + this.formatPrice(MissedFuels);

            const MissedFuelsPerCentage =
              100 - (MissedFuels / Response[0].total_fuel_purchased) * 100;

            this.goalOverview.trainingseries = [
              MissedFuelsPerCentage.toFixed(2),
            ];
          }
        });
    },

    getDriverPayrollData(week, domicile) 
    {
      if(week == '')
      {
        this.onLoadTable(moment().week());

      } else {
        this.onLoadTable(week);

      }

      axios
        .post(`${serverUri}/weekly/payroll_data/${getUserData().company_id}`, {
          week: week,
          domicile: domicile,
        })
        .then((response) => {

          this.PayrollData = [];
          this.totalPayroll = 0;
          response.data.data.map((item) => {
            this.PayrollData.push(item.total_revenue);
            this.totalPayroll = item.total_revenue;
          });

          this.lineChartSeries = [];

          this.lineChartSeries.push({
            name: "Payroll Amount",
            data: this.PayrollData,
          });
        });
    },

    onLoadTable(Selectedweek) {
            this.payroll_items.splice(0, this.payroll_items.length);

            const company = getUserData().company_id;

            axios.post(`${serverUri}/api/payroll/allpayroll/${company}`, {
                year: moment().format('YYYY'),
                week: Selectedweek,
                status: 1
            }).then(res => {
                // calculate total data
                res.data.tripData.map((item1, index) => {
                    const filtering = res.data.rateSetting.filter(item2 => item1.miles >= item2.from_miles && item1.miles <= item2.to_miles)

                    

                    if (this.payroll_items.length && this.payroll_items[this.payroll_items.length - 1].name === item1.driver_name) 
                    {
                        this.payroll_items[this.payroll_items.length - 1].mi_for_fr += filtering.length ? item1.miles : 0;
                        this.payroll_items[this.payroll_items.length - 1].price_for_fr += filtering.length ? filtering[0].fixed_rate : 0;
                        this.payroll_items[this.payroll_items.length - 1].mi_excl_fr += filtering.length ? 0 : item1.miles;
                        this.payroll_items[this.payroll_items.length - 1].price_excl_fr += filtering.length ? 0 : item1.price_per_mile * item1.miles;
                        this.payroll_items[this.payroll_items.length - 1].spot_price = this.payroll_items[this.payroll_items.length - 1].price_for_fr;
                        this.payroll_items[this.payroll_items.length - 1].total_miles += item1.miles;
                        this.payroll_items[this.payroll_items.length - 1].total_price += filtering.length ? filtering[0].fixed_rate : item1.price_per_mile * item1.miles;
                    } else {
                        this.payroll_items.push({
                            id: this.payroll_items.length + 1,
                            name: item1.driver_name,
                            driver_id: item1.driver_id,
                            ssn: item1.ssn,
                            description: item1.description,
                            mi_for_fr: filtering.length ? item1.miles : 0,
                            price_for_fr: filtering.length ? filtering[0].fixed_rate : 0,
                            price_per_mile: item1.price_per_mile,
                            mi_excl_fr: filtering.length ? 0 : item1.miles,
                            price_excl_fr: filtering.length ? 0 : item1.price_per_mile * item1.miles,
                            spot_cnt: 0,
                            spot_price: 0,
                            miles_driven: 0,
                            total_miles: item1.miles,
                            total_price: filtering.length ? filtering[0].fixed_rate : item1.price_per_mile * item1.miles,
                        });
                    }

                })
                
                var SpotDataList = [];
                res.data.spotData.map((item1, index) => {
                    const filteringSopt = res.data.spotSetting.filter(
                        (item2) =>
                            item1.miles >= item2.from_miles &&
                            item1.miles <= item2.to_miles
                    );

                    this.payroll_items.map((item2) => {
                        if (item2.name === item1.driver_name) 
                        {
                           
                        } else {


                            if (SpotDataList.length && SpotDataList[SpotDataList.length - 1].name === item1.driver_name) 
                            {
                              
                            } else {                              

                                    
                                    SpotDataList.push({
                                        id: this.payroll_items.length + 1,
                                        name: item1.driver_name,
                                        driver_id: item1.driver_id,
                                        ssn: item1.ssn,
                                        description: item1.description,
                                        mi_for_fr: 0,
                                        price_for_fr: 0,
                                        price_per_mile: item1.price_per_mile,
                                        mi_excl_fr: 0,
                                        price_excl_fr: 0,
                                        spot_cnt: 0,
                                        spot_price: 0,
                                        miles_driven: 0,
                                        total_miles: item1.miles,
                                        total_price: 0,
                                    })
                            }
                        }
                    })
                })

                for(var i=0; i<SpotDataList.length;i++)
                {
                    var exists = this.payroll_items.some(function(field) {
                        return field.name === SpotDataList[i].name;
                    });

                    var k  = 0 ;
                    if(!exists)
                    {
                        this.payroll_items[parseInt(this.payroll_items.length)+parseInt(k)] = SpotDataList[i];
                        k++;
                    }
                }

                res.data.spotData.map((itemNew, index) => {
                    const filteringSopt = res.data.spotSetting.filter(
                        (item2) =>
                        itemNew.miles >= item2.from_miles &&
                        itemNew.miles <= item2.to_miles
                    );

                    this.payroll_items.map((item2) => {
                        if (item2.name === itemNew.driver_name) 
                        {
                            item2.spot_cnt += 1;
                            item2.spot_price += filteringSopt.length ? filteringSopt[0].spot_pay : 0;
                            item2.miles_driven += !filteringSopt.length ? itemNew.miles : 0;
                            item2.total_miles += itemNew.miles;
                            item2.total_price += filteringSopt.length ? filteringSopt[0].spot_pay : itemNew.miles * itemNew.price_per_mile;
                        }
                    })
                });

                this.totalRows = this.payroll_items.length

                // 
                axios
                .post(`${serverUri}/drivers/add_payroll_data_items`, {
                  company_id:company,
                  week:Selectedweek,
                  payroll_items: this.payroll_items,
                })
                .then((response) => {


                  if(response.data.status)
                  {
                      this.getWeekLabels(Selectedweek,'current');
                      this.dataLoad = false;


                  } else {
                    this.dataLoad = false;

                  }

                });

            });
        },
  },
};
</script>
<style>
.code-toggler {
  display: none;
}
.todo-select {
  width: 120px;
}
.todo-body .card-header {
  padding: 1.1rem 1.5rem;
}
.todo-body .card-body {
  padding: 0px;
}
.todo-body hr {
  margin: 0px;
}
.todo-body .form-group {
  margin-bottom: 0px;
}
.to-do-tick {
  color: #7367f0;
}
/* .compliance-drivers-table {
  overflow-y: auto;
  max-height: 115px !important;
} */
.compliance-drivers-table tr {
  background: rgb(243 242 247);
}
.compliance-drivers-table table {
  border-collapse: separate !important;
  border-spacing: 0px 5px !important;
  padding: 0px 5px !important;
}
.compliance-drivers-table td:first-child {
  width: 20%;
}
.compliance-drivers-table td {
  padding: 0.72rem 1rem;
}
.vedr .truncate span {
  font-size: 22px;
  font-weight: bold;
  color: rgb(114 0 240);
}
.vedr .truncate h2 {
  font-size: 15px;
  font-weight: normal !important;
}
.vedr-table .b-table-sticky-header {
  overflow-y: auto;
  max-height: 470px;
}
.custom-badge-card .card-statistics .statistics-body {
  padding: 1rem 1.4rem 1.8rem !important;
}
.custom-badge-card .card-statistics .statistics-body .top-small-heading {
  color: #2a0f4c;
}
.custom-badge-card .card-statistics .statistics-body .numbering-result {
  color: #2a0f4c;
}
.apexchartforqvistatus .apexcharts-datalabel-value {
  fill: #7200f0 !important;
  font-weight: 600 !important;
}
</style>