<template>
  <div>
    <weekly-dashboard-section1 />
    <!-- <weekly-dashboard-section4 /> -->
    <weekly-dashboard-section7 />
  </div>
</template>


<script>
import WeeklyDashboardSection1 from "./weeklysection1.vue";
//import WeeklyDashboardSection2 from "./weeklysection2.vue";
//import WeeklyDashboardSection4 from "./weeklysection4.vue";
import WeeklyDashboardSection7 from "./weeklysection7.vue";

export default {
  components: {
    WeeklyDashboardSection1,
    // WeeklyDashboardSection2,
    //WeeklyDashboardSection4,
    WeeklyDashboardSection7,
  },
 
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
<style>
h4 {
  font-size: 25px !important;
}
</style>
