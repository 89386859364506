<template>
  <div>
    <b-row>
      <b-col
        v-for="item in statisticsItems"
        :key="item.icon"
        md="6"
        sm="3"
        class="mb-2 mb-md-0"
        :class="item.customClass"
      >
        <b-card no-body class="card-statistics">
          <b-card-body class="statistics-body p-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
              <div class="statistics-icon">
                  <b-avatar size="70" :variant="item.color">
                    <img :src="item.mySVG" />
                  </b-avatar>
                </div>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-card-text class="font-small-3 mb-0 top-small-heading">
                  {{ item.subtitle }}
                </b-card-text>
                <h4 class="font-weight-bolder mb-0 numbering-result">
                  {{ item.title }}
                </h4>
                <div
                  class="font-medium-2"
                  style="margin-top: 5px; font-size: 1rem !important"
                >
                  <span class="mr-1" :class="item.belowtextcolor">
                    <feather-icon
                      size="28"
                      :icon="item.belowtextupdown"
                      style="width: 15px !important; height: 15px !important"
                    />
                    {{ item.belowtext }}
                  </span>
                </div>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormGroup,
} from "bootstrap-vue";
import FuelIcon from "@/assets/images/svg/mdi_fossil-fuel-outline.svg";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import vSelect from "vue-select";
import moment from "moment";
import axios from "axios";
import Vue from 'vue';

window.eventBus = new Vue();

export default {
  components: {
    FuelIcon,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormGroup,
    vSelect,
  },
  props: ['localObject', 'selectedWeek','selectedDomicile'],

  data() {
    return {
      statisticsItems: [
        {
          icon: "DollarSignIcon",
          color: "light-primary",
          title: "$0",
          subtitle: "Revenue",
          customClass: "mb-2 mb-xl-0",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/uil_dollar-sign-alt.svg"),
        },
        {
          icon: "TrendingUpIcon",
          color: "light-info",
          title: "0",
          subtitle: "Miles",
          customClass: "mb-2 mb-xl-0",
          belowtextcolor: "text-danger",
          belowtextupdown: "ArrowDownCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/grommet-icons_line-chart.svg"),
        },
        {
          icon: "TruckIcon",
          color: "light-success",
          title: "$0",
          subtitle: "Fuel",
          customClass: "",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/mdi_fossil-fuel-outline.svg"),
        },
        {
          icon: "UserIcon",
          color: "light-danger",
          title: "0",
          subtitle: "Drivers",
          customClass: "",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/healthicons_truck-driver.svg"),
        },
      ],
    };
  },

  beforeDestroy() {
    EventBus.$off("applyFilter");
  },

  created() {
    
    this.getCountedData(this.selectedWeek.title,this.selectedDomicile.title);

    EventBus.$on('applyFilter', (item) => {

          this.getCountedData(item.week,item.domicile);
    });

  },
  methods: {

    formatPrice(value) {
        // let val = (value/1).toFixed(2).replace('.', ',')
        // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
    },

    DomicileSelect() {
      this.getCountedData();
    },

    getCountedData(Week,Domicile) 
    {
      const response = axios
        .post(`${serverUri}/weekly/counted_data/${getUserData().company_id}`, {
          week: Week,
          domicile: Domicile,
        })
        .then((response) => {

          const WeeklyCountedData = response.data.current_week_data;
          if(WeeklyCountedData[0] != undefined)
          {
              const LastWeekCountedData = response.data.last_week_data;

              if(WeeklyCountedData[0].totalSpotMiles == null)
              {
                var CurrentWeekTotalMiles = parseFloat(WeeklyCountedData[0].totalMiles);
                var LastWeekTotalMiles = parseFloat(LastWeekCountedData[0].totalMiles);


              } else {

                var CurrentWeekTotalMiles = parseFloat(WeeklyCountedData[0].totalMiles) + parseFloat(WeeklyCountedData[0].totalSpotMiles);
                var LastWeekTotalMiles = parseFloat(LastWeekCountedData[0].totalMiles) + parseFloat(LastWeekCountedData[0].totalSpotMiles);

              }



              if(WeeklyCountedData[0].totalSpotAmount == null)
              {
                var CurrentWeekTotalRevenue = parseFloat(WeeklyCountedData[0].totalRevenue);
                var LastWeekTotalRevenue    = parseFloat(LastWeekCountedData[0].totalRevenue);


              } else {


                var CurrentWeekTotalRevenue = parseFloat(WeeklyCountedData[0].totalRevenue) + parseFloat(WeeklyCountedData[0].totalSpotAmount);
                var LastWeekTotalRevenue = parseFloat(LastWeekCountedData[0].totalRevenue) + parseFloat(LastWeekCountedData[0].totalSpotAmount);



              }              

              this.statisticsItems[0].title =
                "$" + this.formatPrice(CurrentWeekTotalRevenue);
              this.statisticsItems[1].title = this.formatPrice(CurrentWeekTotalMiles);
              this.statisticsItems[2].title =
                "$" + this.formatPrice(WeeklyCountedData[0].totalFuels);
              this.statisticsItems[3].title = WeeklyCountedData[0].totalDrivers;

              LastWeekCountedData.totalRevenue = LastWeekTotalRevenue;
              LastWeekCountedData.totalMiles = LastWeekTotalMiles;
              LastWeekCountedData.totalFuels = LastWeekCountedData[0].totalFuels;
              LastWeekCountedData.totalDrivers =
                LastWeekCountedData[0].totalDrivers;

              var DriversGrowth;
              var TotalDriversGrowth;
              var DriverStatus;

              if (
                LastWeekCountedData.totalDrivers > WeeklyCountedData[0].totalDrivers
              ) {
                DriversGrowth =
                  parseFloat(LastWeekCountedData.totalDrivers) -
                  parseFloat(WeeklyCountedData[0].totalDrivers);
                DriverStatus = false;

                this.statisticsItems[3].belowtextcolor = "text-danger";
                this.statisticsItems[3].belowtextupdown = "ArrowDownCircleIcon";
              } else {
                DriversGrowth =
                  parseFloat(WeeklyCountedData[0].totalDrivers) -
                  parseFloat(LastWeekCountedData.totalDrivers);
                DriverStatus = true;

                this.statisticsItems[3].belowtextcolor = "text-success";
                this.statisticsItems[3].belowtextupdown = "ArrowUpCircleIcon";
              }

              TotalDriversGrowth =
                parseFloat(LastWeekCountedData.totalDrivers) +
                parseFloat(WeeklyCountedData[0].totalDrivers);
              const calculatedDriversGrowth =
                (DriversGrowth / TotalDriversGrowth) * 100;
              this.statisticsItems[3].belowtext =
                calculatedDriversGrowth.toFixed(2) + "% VS last week";

              var FuelsGrowth;
              var TotalFuelsGrowth;
              var FuelStatus;
              if (
                LastWeekCountedData.totalFuels > WeeklyCountedData[0].totalFuels
              ) {
                FuelsGrowth =
                  parseFloat(LastWeekCountedData.totalFuels) -
                  parseFloat(WeeklyCountedData[0].totalFuels);
                FuelStatus = false;

                this.statisticsItems[2].belowtextcolor = "text-success";
                this.statisticsItems[2].belowtextupdown = "ArrowUpCircleIcon";
              } else {
                FuelsGrowth =
                  parseFloat(WeeklyCountedData[0].totalFuels) -
                  parseFloat(LastWeekCountedData.totalFuels);
                FuelStatus = true;

                this.statisticsItems[2].belowtextcolor = "text-danger";
                this.statisticsItems[2].belowtextupdown = "ArrowDownCircleIcon";
              }

              TotalFuelsGrowth =
                parseFloat(LastWeekCountedData.totalFuels) +
                parseFloat(WeeklyCountedData[0].totalFuels);
              const calculatedFuelsGrowth = (FuelsGrowth / TotalFuelsGrowth) * 100;

              this.statisticsItems[2].belowtext =
                calculatedFuelsGrowth.toFixed(2) + "% VS last week";

              var MilesGrowth;
              var TotalMilesGrowth;
              var MileStatus;
              if (
                LastWeekCountedData.totalMiles > WeeklyCountedData[0].totalMiles
              ) {
                MilesGrowth =
                  parseFloat(LastWeekCountedData.totalMiles) -
                  parseFloat(WeeklyCountedData[0].totalMiles);
                MileStatus = false;

                this.statisticsItems[1].belowtextcolor = "text-danger";
                this.statisticsItems[1].belowtextupdown = "ArrowDownCircleIcon";
              } else {
                MilesGrowth =
                  parseFloat(WeeklyCountedData[0].totalMiles) -
                  parseFloat(LastWeekCountedData.totalMiles);
                MileStatus = true;

                this.statisticsItems[1].belowtextcolor = "text-success";
                this.statisticsItems[1].belowtextupdown = "ArrowUpCircleIcon";
              }

              TotalMilesGrowth =
                parseFloat(LastWeekCountedData.totalMiles) +
                parseFloat(WeeklyCountedData[0].totalMiles);
              const calculatedMilesGrowth = (MilesGrowth / TotalMilesGrowth) * 100;

              this.statisticsItems[1].belowtext =
                calculatedMilesGrowth.toFixed(2) + "% VS last week";

              var RevenueGrowth;
              var TotalRevenueGrowth;
              var RevenueStatus;
              if (
                LastWeekCountedData.totalRevenue > WeeklyCountedData[0].totalRevenue
              ) {
                RevenueGrowth =
                  parseFloat(LastWeekCountedData.totalRevenue) -
                  parseFloat(WeeklyCountedData[0].totalRevenue);
                RevenueStatus = false;

                this.statisticsItems[0].belowtextcolor = "text-danger";
                this.statisticsItems[0].belowtextupdown = "ArrowDownCircleIcon";
              } else {
                RevenueGrowth =
                  parseFloat(WeeklyCountedData[0].totalRevenue) -
                  parseFloat(LastWeekCountedData.totalRevenue);
                RevenueStatus = true;

                this.statisticsItems[0].belowtextcolor = "text-success";
                this.statisticsItems[0].belowtextupdown = "ArrowUpCircleIcon";
              }

              TotalRevenueGrowth =
                parseFloat(LastWeekCountedData.totalRevenue) +
                parseFloat(WeeklyCountedData[0].totalRevenue);

              const calculatedRevenueGrowth =
                (RevenueGrowth / TotalRevenueGrowth) * 100;

              this.statisticsItems[0].belowtext =
                calculatedRevenueGrowth.toFixed(2) + "% VS last week";

            } else {

              this.statisticsItems =  [
        {
          icon: "DollarSignIcon",
          color: "light-primary",
          title: "$0",
          subtitle: "Revenue",
          customClass: "mb-2 mb-xl-0",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/uil_dollar-sign-alt.svg"),
        },
        {
          icon: "TrendingUpIcon",
          color: "light-info",
          title: "0",
          subtitle: "Miles",
          customClass: "mb-2 mb-xl-0",
          belowtextcolor: "text-danger",
          belowtextupdown: "ArrowDownCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/grommet-icons_line-chart.svg"),
        },
        {
          icon: "TruckIcon",
          color: "light-success",
          title: "$0",
          subtitle: "Fuel",
          customClass: "",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/mdi_fossil-fuel-outline.svg"),
        },
        {
          icon: "UserIcon",
          color: "light-danger",
          title: "0",
          subtitle: "Drivers",
          customClass: "",
          belowtextcolor: "text-success",
          belowtextupdown: "ArrowUpCircleIcon",
          belowtext: "0% vs last week",
          mySVG: require("@/assets/images/svg/healthicons_truck-driver.svg"),
        },
      ];
            } 
        });

        
    },
  },
};
</script>
<style>
.numbering-result {
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #7200f0;
}
.top-small-heading {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.5;
}
[dir] .card-statistics .statistics-body {
  padding: 2rem 1.4rem 1.8rem !important;
}

@media (max-width: 767.98px) {
  .numbering-result {
    font-size: 10px;
    font-weight: 700 !important;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #7200f0; /* Smaller font size on small screens */
  }
  .top-small-heading {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.5; /* Adjust font size for smaller heading */
  }
  .statistics-icon {
    display: none;
    
  }
}
</style>
